<template>
    <div class="modal-premium-info">
        <div class="title">프리미엄 주선</div>
        <div class="body" v-if="options.body" v-html="options.body" />
        <div class="info" v-if="options.info" v-html="options.info" />
        <button class="btn up-button" @click="onClickButton(0)" v-html="options.upButton" />
        <button class="btn down-button" @click="onClickButton(1)" v-html="options.downButton" />
    </div>
</template>

<script>
export default {
    name: 'ModalPremiumInfo',
    props: ['options'],
    mounted() {
        document.addEventListener('keydown', this.onKeydown)
    },
    beforeDestroy() {
        document.removeEventListener('keydown', this.onKeydown)
    },
    computed: {},
    methods: {
        onClickButton(idx) {
            this.$emit('close', idx)
        },
        onKeydown(event) {
            if (event.key === 'Enter' && this.buttons) {
                const confirmButton = this.buttons[this.buttons.length - 1]
                if (confirmButton) this.dropout()
            }
        },
    },
}
</script>

<style lang="scss" scoped>
.modal-premium-info {
    width: 280px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @include f-regular;

    .title {
        font-size: 18px;
        color: black;
        @include f-medium;
        margin-bottom: 12px;
    }

    .body {
        font-size: 15px;
        color: black;
        line-height: normal;
    }

    .info {
        font-size: 14px;
        color: $grey-08;
        line-height: normal;
    }

    .up-button {
        width: 100%;
        height: 36px;
        font-size: 14px;
        background: $purple-primary;
        border-radius: 8px;
        color: white;
        margin-top: 20px;
    }

    .down-button {
        width: 100%;
        height: 36px;
        font-size: 14px;
        background: none;
        border-radius: 8px;
        border: solid 1px $grey-03;
        color: $grey-08;
        margin-top: 8px;
    }
}
</style>
